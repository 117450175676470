import React, {useState} from 'react';
// import { useTranslation } from "react-i18next";
import './packagingAndDisplay.scss';
import { Link } from "react-router-dom";
import { toRoute } from "../../i18n";
// import Image from "../../components/image";
import { asset } from "../../helpers/dom.helper";
import { Media, MediaContextProvider } from '../../media';
import ModalDownload from '../itemAndBlog/itemListContainer/modalDownload';

const PackagingAndDisplay = React.memo(() => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  // const { t } = useTranslation(['translation', 'home'])
  return (
    <MediaContextProvider>
      <Media greaterThanOrEqual="md">
        <div id="packagingAndDisplay">
          <div className='textHeader'>
            <h2>Packaging and Display</h2>
          </div>
          <div className='containerPic1'>
            <div className='leftContainer'>
              <Link to={toRoute('/packaging/jewelry-boxes')}>
                <img
                  alt='jewelryBox'
                  src={asset('/images/home/packaging/jewelryBox.webp')}  
                  className='zoom'
                  width="516px"
                  height="516px"
                />
              </Link>
              <p className='namePackaging'>Jewelry Boxes</p>
            </div>
            <div className='rightContainer'>
              <div className='childContainer'>
                <Link to={toRoute('/packaging/jewelry-pouches')}>
                  <img
                    alt='jewelryPouch'
                    src={asset('/images/home/packaging/jewelryPouch.webp')}   
                    className='zoom'
                    width="516px"
                    height="247px"           
                  />
                </Link>
                <p className='namePackaging'>Jewelry Pouches</p>          
              </div>
              <div className='childContainer'>
                <Link to={toRoute('/packaging/jewelry-cases')}>
                  <img
                    alt='jewelryCase'
                    src={asset('/images/home/packaging/jewelryCase.webp')}   
                    className='zoom'
                    width="516px"
                    height="247px"            
                  />
                </Link>
                <p className='namePackaging'>Jewelry Case</p>          
              </div>
            </div>
          </div>

          <div className='containerPic2'>
            <div className='leftContainer'>
              <div className='childContainer'>
                <Link to={toRoute('/packaging/shopping-aids')}>
                  <img
                    alt='shoppingAids'
                    src={asset('/images/home/packaging/shoppingAidses.webp')}   
                    className='zoom'
                    width="516px"
                    height="247px"              
                  />
                </Link>
                <p className='namePackaging'>Shopping Aids</p>       
              </div>
              <div className='childContainer'>
                <Link to={toRoute('/packaging/premium-paper-bags')}>
                  <img
                    alt='premiumPaperBag'
                    src={asset('/images/home/packaging/premiumPaperBag.webp')}      
                    className='zoom'
                    width="516px"
                    height="247px"         
                  />
                </Link>
                <p className='namePackaging'>Premium Paper Bags</p>                   
              </div>
            </div>
            <div className='rightContainer'>
              <Link to={toRoute('/packaging/jewelry-displays')}>
              <img
                  alt='jewelryDisplay'
                  src={asset('/images/home/packaging/jewelryDisplay.webp')}  
                  className='zoom'
                  width="516px"
                  height="516px"   
                />
              </Link>
              <p className='namePackaging'>Jewelry Displays</p>
            </div>
          </div>

          <div className='containerPic1'>
            <div className='leftContainer'>
              <Link to={toRoute('/packaging/watch-packaging')}>
                <img
                  alt='watchPackaging'
                  src={asset('/images/home/packaging/watchPackaging.webp')}  
                  className='zoom'
                  width="516px"
                  height="516px"   
                />
              </Link>
              <p className='namePackaging'>Watch Packaging</p>
            </div>
            <div className='rightContainer'>
              <div className='childContainer'>
                <Link to={toRoute('/packaging/watch-displays')}>
                  <img
                    alt='watchDisplay'
                    src={asset('/images/home/packaging/watchDisplay.webp')}      
                    className='zoom'
                    width="516px"
                    height="247px"           
                  />
                </Link>
                <p className='namePackaging'>Watch Display</p>
              </div>
              <div className='childContainer'>
                <Link to={toRoute('/packaging/smart-packaging')}>
                  <img
                    alt='smartPackaging'
                    src={asset('/images/home/packaging/smartPackaging.webp')} 
                    className='zoom'
                    width="516px"
                    height="247px"              
                  />
                </Link>
                <p className='namePackaging'>Smart Packaging</p>
              </div>
            </div>
          </div>

          <div className='containerPic3'>
            <div className='topContainer'>
              <div className='childContainer'>
                <Link to={toRoute('/packaging/e-commerce-assistant')}>
                  <img
                    alt='ecommerceAssistant'
                    src={asset('/images/home/packaging/ecommerceAssistant.webp')}    
                    className='zoom'
                    width="516px"
                    height="247px"             
                  />
                </Link>
                <p className='namePackaging left'>E-Commerce Assistant</p>
              </div>
              <div className='childContainer'>
                <Link to={toRoute('/packaging/eco-friendly-packaging')}>
                  <img
                    alt='ecoFriendlyPackaging'
                    src={asset('/images/home/packaging/ecoFriendlyPackaging.webp')}   
                    className='zoom'
                    width="516px"
                    height="247px"            
                  />
                </Link>
                <p className='namePackaging right'>Eco-Friendly Packaging</p>
              </div>
            </div>
            <div className='botContainer'>          
              <Link to={toRoute('/packaging/customized-projects')}>
                <img
                  alt='customizedProject'
                  src={asset('/images/home/packaging/customizedProject.webp')}  
                  className='zoom'
                  width="100%"
                  height="247px"   
                />
              </Link>
              <p className='namePackaging'>Customized Projects</p>
            </div>
          </div>

          <div className='downloadCatalogue'>
            <button onClick={showModal}>
              Download Catalogue
            </button>
          </div>
          <ModalDownload isModalVisible={isModalVisible} onModalClose={() => setIsModalVisible(false)} />
          <div className='thailandCraftsmanship'>
            <img          
              alt='thailandCraftsmanship'
              src={asset('/images/home/packaging/thialandCraftsmanship.webp')}  
              width="100%"
              height="auto"
            />
            {/* <div className='thailandCraftsmanshipText'>
              <p className="thailandText">Thailand</p>
              <p className="craftmanText">Craftsmanship</p>
            </div> */}
          </div>
        </div>
      </Media>
      <Media lessThan="md">
      <div id="packagingAndDisplayMobile">
          <div className='textHeader'>
            <h2>Packaging and Display</h2>
          </div>
            <div className='packagingContainer'>
              <Link to={toRoute('/packaging/jewelry-boxes')}>
                <img
                  alt='jewelryBox'
                  src={asset('/images/home/packaging/jewelryBox.webp')}  
                  width="100%"
                  height="auto"
                />
              </Link>
              <p className='namePackaging'>Jewelry Boxes</p>
            </div>

            <div className='packagingContainer'>
                <Link to={toRoute('/packaging/jewelry-pouches')}>
                  <img
                    alt='jewelryPouch'
                    src={asset('/images/home/packaging/jewelryPouch.webp')}   
                    width="100%"
                    height="auto"        
                  />
                </Link>
                <p className='namePackaging'>Jewelry Pouches</p>    
              </div>

              <div className='packagingContainer'>
                <Link to={toRoute('/packaging/jewelry-cases')}>
                  <img
                    alt='jewelryCase'
                    src={asset('/images/home/packaging/jewelryCase.webp')}   
                    width="100%"
                    height="auto"            
                  />
                </Link>
                <p className='namePackaging'>Jewelry Case</p>          
              </div>
                      
              <div className='packagingContainer'>
                <Link to={toRoute('/packaging/shopping-aids')}>
                  <img
                    alt='shoppingAids'
                    src={asset('/images/home/packaging/shoppingAidses.webp')}   
                    width="100%"
                    height="auto"            
                  />
                </Link>
                <p className='namePackaging'>Shopping Aids</p>       
              </div>

              <div className='packagingContainer'>
                <Link to={toRoute('/packaging/premium-paper-bags')}>
                  <img
                    alt='premiumPaperBag'
                    src={asset('/images/home/packaging/premiumPaperBag.webp')}      
                    className='zoom'
                    width="100%"
                    height="auto"       
                  />
                </Link>
                <p className='namePackaging'>Premium Paper Bags</p>                   
              </div>
            
              <div className='packagingContainer'>
                <Link to={toRoute('/packaging/jewelry-displays')}>
                <img
                    alt='jewelryDisplay'
                    src={asset('/images/home/packaging/jewelryDisplay.webp')}  
                    width="100%"
                    height="auto"    
                  />
                </Link>
                <p className='namePackaging'>Jewelry Displays</p>
              </div>
           

            <div className='packagingContainer'>
              <Link to={toRoute('/packaging/watch-packaging')}>
                <img
                  alt='watchPackaging'
                  src={asset('/images/home/packaging/watchPackaging.webp')}  
                  width="100%"
                  height="auto"  
                />
              </Link>
              <p className='namePackaging'>Watch Packaging</p>
            </div>

              <div className='packagingContainer'>
                <Link to={toRoute('/packaging/watch-displays')}>
                  <img
                    alt='watchDisplay'
                    src={asset('/images/home/packaging/watchDisplay.webp')}      
                    width="100%"
                    height="auto"          
                  />
                </Link>
                <p className='namePackaging'>Watch Display</p>
              </div>

              <div className='packagingContainer'>
                <Link to={toRoute('/packaging/smart-packaging')}>
                  <img
                    alt='smartPackaging'
                    src={asset('/images/home/packaging/smartPackaging.webp')} 
                    width="100%"
                    height="auto"              
                  />
                </Link>
                <p className='namePackaging'>Smart Packaging</p>
              </div>

              <div className='packagingContainer'>
                <Link to={toRoute('/packaging/e-commerce-assistant')}>
                  <img
                    alt='ecommerceAssistant'
                    src={asset('/images/home/packaging/ecommerceAssistant.webp')}    
                    width="100%"
                    height="auto"            
                  />
                </Link>
                <p className='namePackaging left'>E-Commerce Assistant</p>
              </div>

              <div className='packagingContainer'>
                <Link to={toRoute('/packaging/eco-friendly-packaging')}>
                  <img
                    alt='ecoFriendlyPackaging'
                    src={asset('/images/home/packaging/ecoFriendlyPackaging.webp')}   
                    width="100%"
                    height="auto"          
                  />
                </Link>
                <p className='namePackaging right'>Eco-Friendly Packaging</p>
              </div>

              <div className='packagingContainer'>          
                <Link to={toRoute('/packaging/customized-projects')}>
                  <img
                    alt='customizedProject'
                    src={asset('/images/home/packaging/customizedProject.webp')}  
                    width="auto"
                    height="auto"  
                  />
                </Link>
                <p className='namePackaging'>Customized Projects</p>
              </div>


          <div className='downloadCatalogue'>
            <button  onClick={showModal}>
              Download Catalogue
            </button>
          </div>
          <ModalDownload isModalVisible={isModalVisible} onModalClose={() => setIsModalVisible(false)} />

          <div className='thailandCraftsmanship'>
            <img          
              alt='thailandCraftsmanship'
              src={asset('/images/home/packaging/thialandCraftsmanship-mobile.webp')}  
            />
          </div> 
        </div>
      </Media>
    </MediaContextProvider>
  )


})

export default PackagingAndDisplay