import React from 'react';
import './readOurBlogs.scss'
import { Link } from "react-router-dom";
import { asset } from "../../helpers/dom.helper";
import { toRoute } from "../../i18n";
import { Media, MediaContextProvider } from '../../media';
import { PACKAGING_BLOG } from '../packagingBlog/packagingBlogJson';

const ReadOurBlog = React.memo(() => {

  return (
    <MediaContextProvider>
      <Media greaterThanOrEqual="md">
        <div id="readOurBlog">      
            <div className="text-header">
              <h2>Read Our Packaging Blog</h2>
              <p>Read the latest in packaging design trends, new materials, sustainable packaging, innovative packaging, and latest packaging news from Lussopack</p>
            </div>        
            <div className='blogContainer'>
              <div className='blogInfo'>
                <Link to={toRoute(`/blog${PACKAGING_BLOG[0].path}`)}>
                  <img          
                    alt={`${PACKAGING_BLOG[0].blogName}`}
                    src={asset(`${PACKAGING_BLOG[0].blogImageDesktop}`)}  
                    width="100%"
                    height="220px"
                  />
                  <h3>{PACKAGING_BLOG[0].blogName}</h3>
                </Link>
                <p>Post on: {PACKAGING_BLOG[0].datePost}</p>
              </div>
              <div className='blogInfo'>
              <Link to={toRoute(`/blog${PACKAGING_BLOG[1].path}`)}>
                  <img          
                    alt={`${PACKAGING_BLOG[1].blogName}`}
                    src={asset(`${PACKAGING_BLOG[1].blogImageDesktop}`)}  
                    width="100%"
                    height="220px"
                  />
                  <h3>{PACKAGING_BLOG[1].blogName}</h3>
                </Link>
                <p>Post on: {PACKAGING_BLOG[1].datePost}</p>
              </div>
              <div className='blogInfo'>
                <Link to={toRoute(`/blog${PACKAGING_BLOG[2].path}`)}>
                  <img          
                    alt={`${PACKAGING_BLOG[2].blogName}`}
                    src={asset(`${PACKAGING_BLOG[2].blogImageDesktop}`)}  
                    width="100%"
                    height="220px"
                  />
                  <h3>{PACKAGING_BLOG[2].blogName}</h3>
                </Link>
                <p>Post on: {PACKAGING_BLOG[2].datePost}</p>
              </div>
            </div>
            <p className='readmoreContainer'> <Link to={toRoute('/blog')} aria-label="Read more packaging blog">Read more packaging blog</Link></p>
        </div>
      </Media>
      
      
      
      
      <Media lessThan="md">
        <div id="readOurBlogMobile">      
            <div className="text-header">
              <h2>Read Our Packaging Blog</h2>
              <p>Read the latest in packaging design trends, new materials, sustainable packaging, innovative packaging, and latest packaging news from Lussopack</p>
            </div>      

            <div className='blogContainer'>
              <div className='blogInfo'>
                <Link to={toRoute(`/blog${PACKAGING_BLOG[0].path}`)}>
                  <img          
                    alt={`${PACKAGING_BLOG[0].blogName}`}
                    src={asset(`${PACKAGING_BLOG[0].blogImageDesktop}`)}  
                    width="100%"
                    height="auto"
                  />
                  <h3>{PACKAGING_BLOG[0].blogName}</h3>
                </Link>
                <p>Post on: {PACKAGING_BLOG[0].datePost}</p>
              </div>
              <div className='blogInfo'>
                <Link to={toRoute(`/blog${PACKAGING_BLOG[1].path}`)}>
                  <img          
                    alt={`${PACKAGING_BLOG[0].blogName}`}
                    src={asset(`${PACKAGING_BLOG[1].blogImageDesktop}`)}  
                    width="100%"
                    height="auto"
                  />
                  <h3>{PACKAGING_BLOG[1].blogName}</h3>
                </Link>
                <p>Post on: {PACKAGING_BLOG[1].datePost}</p>
              </div>
              <div className='blogInfo'>
                <Link to={toRoute(`/blog${PACKAGING_BLOG[2].path}`)}>
                  <img          
                    alt={`${PACKAGING_BLOG[2].blogName}`}
                    src={asset(`${PACKAGING_BLOG[2].blogImageDesktop}`)}  
                    width="100%"
                    height="auto"
                  />
                  <h3>{PACKAGING_BLOG[2].blogName}</h3>
                </Link>
                <p>Post on: {PACKAGING_BLOG[2].datePost}</p>
              </div>
            </div>

            <p className='readmoreContainer'> <Link to={toRoute('/blog')} aria-label="Read more packaging blog">Read more packaging blog</Link></p>
        </div>
      </Media>
    </MediaContextProvider>
  )
})

export default ReadOurBlog