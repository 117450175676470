import React, { useState } from "react";
import "./modalDowmload.scss";
import Image from "../../../components/image";
import { asset } from "../../../helpers/dom.helper";
import { Media, MediaContextProvider } from "../../../media";

function ModalDownload(props) {
  const [inputFirstName, setInputFirstName] = useState(null);
  const [inputLastName, setInputLastName] = useState(null);
  const [inputEmailName, setInputEmailName] = useState(null);
  const [inputAccept1, setInputAccept1] = useState(false);
  const [inputAccept2, setInputAccept2] = useState(false);
  const [downloadDone, setDownloadDone] = useState(false);

  const dataInfo = JSON.stringify({
    "first_name": inputFirstName,
    "last_name": inputLastName,
    "email": inputEmailName,
    "accept1": `${inputAccept1}`,
    "accept2": `${inputAccept2}`,
  });

  const handleInputChange = (e, setInput, checkBox) => {
    if (checkBox === 'setInputAccept1' || checkBox === 'setInputAccept2') {
      setInput(e.target.checked);
    } else {
      setInput(e.target.value);
    }
  };

  const downloadFile = () => {
    if(inputFirstName && inputLastName && inputEmailName) 
    { 
        const myHeadersGenToken = new Headers();
        myHeadersGenToken.append("Content-Type", "application/json");
        
        const rawGenToken = JSON.stringify({
            "username": "lussopack",
            "password": "108689lussopack"
        });
        
        const requestOptionsToken = {
            method: 'POST',
            headers: myHeadersGenToken,
            body: rawGenToken,
            redirect: 'follow'
        };
        
        fetch(`https://lussopack-backend-app-bfyz8.ondigitalocean.app/generate_token`, requestOptionsToken)
            .then(response => response.json()) 
            .then(result => {
                const token = result.token;

                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", `Bearer ${token}`);

                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: dataInfo,
                  redirect: 'follow'
                };

                fetch("https://lussopack-backend-app-bfyz8.ondigitalocean.app/insert_download_file", requestOptions)
                  .then(response => response.json())
                  .then(result => {
                    if(result.message === "Download file inserted successfully"){
                      setDownloadDone(true)

                      const downloadLink = document.createElement('a');
                      downloadLink.href = 'https://lussopack.s3.ap-southeast-1.amazonaws.com/File/New+Material+for+packaging.pdf';
                      downloadLink.download = 'New_Material_for_packaging.pdf';
                      downloadLink.target = '_blank';
                      downloadLink.click();
                    }
                  }).catch(error => console.log('error', error));
            })
            .catch(error => console.log('error', error));
      }
  }

  const closeModalHandle = () => {
    setDownloadDone(false)
    setInputFirstName(null)
    setInputLastName(null)
    setInputEmailName(null)
    setInputAccept1(false)
    setInputAccept1(false)
    props.onModalClose()
  }

  return (
    props.isModalVisible  &&
    <>
      <MediaContextProvider>
        <Media greaterThanOrEqual="md">
          <div id="downloadModalContainer">
            {downloadDone?
            <div className="downloadDone">
              <h2>Thank you for download</h2>
              <Image            
                    className="cross"
                    alt='cross'
                    src={asset('/icon/cross.svg')}                  
                    width="25"
                    height="25"
                    onClick={closeModalHandle}
                />
            </div>
            :
            <div className="downloadModal">
                <div className="headModal">
                    <h2>Please fill to download</h2>
                    <Image            
                        className="cross"
                        alt='cross'
                        src={asset('/icon/cross.svg')}                  
                        width="25"
                        height="25"
                        onClick={closeModalHandle}
                    />      
                </div>
              <div className="nameInput">
                <input 
                  className="firstName"                   
                  placeholder="First Name*" 
                  value={inputFirstName}
                  autoComplete="nope"
                  onChange={(e) => handleInputChange(e, setInputFirstName)}                  
                  />
                <input 
                  className="lastName" 
                  placeholder="Last Name*"
                  value={inputLastName}
                  autoComplete="nope"
                  onChange={(e) => handleInputChange(e, setInputLastName)}       
                />
              </div>
              <div className="emailInput">
                <input 
                  className="emailName" 
                  placeholder="E-mail*"
                  value={inputEmailName}
                  autoComplete="nope"
                  onChange={(e) => handleInputChange(e, setInputEmailName)}     
                />
              </div>
              <p>lussopack is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you:</p>
              <p>
                <input 
                  type="checkbox" 
                  className="checkbox"
                  checked={inputAccept1}
                  onChange={(e) => handleInputChange(e, setInputAccept1, 'setInputAccept1')}  
                />I agree to receive other communications from lussopack.</p>
              <p>In order to provide you the content requested, we need to store and process your personal data. If you consent to us storing your personal data for this purpose, please tick the checkbox below.</p>
              <p>
                <input 
                  type="checkbox" 
                  className="checkbox"
                  checked={inputAccept2}
                  onChange={(e) => handleInputChange(e, setInputAccept2, 'setInputAccept2')}  
                />
                I agree to allow lussopack to store and process my personal data.*</p>
              <p>You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy.</p>
              <div className="downLoadFile">
                <button onClick={downloadFile}>Download File</button>
              </div>
            </div>}
          </div>
        </Media>
        <Media lessThan="md">
        <div id="downloadModalContainerMobile">
          {downloadDone?
            <div className="downloadDone">
              <h2>Thank you for download</h2>
              <Image            
                    className="cross"
                    alt='cross'
                    src={asset('/icon/cross.svg')}                  
                    width="25"
                    height="25"
                    onClick={closeModalHandle}
                />
            </div>:
            <div className="downloadModal">
                <div className="headModal">
                    <h2>Please fill to download</h2>
                    <Image            
                        className="cross"
                        alt='cross'
                        src={asset('/icon/cross.svg')}                  
                        width="25"
                        height="25"
                        onClick={closeModalHandle}
                    />      
                </div>
              <div className="inputContainer">
              <div className="nameInput">
                <input 
                  className="firstName"                   
                  placeholder="First Name*" 
                  value={inputFirstName}
                  autoComplete="nope"
                  onChange={(e) => handleInputChange(e, setInputFirstName)}                  
                  />
                <input 
                  className="lastName" 
                  placeholder="Last Name*"
                  value={inputLastName}
                  autoComplete="nope"
                  onChange={(e) => handleInputChange(e, setInputLastName)}       
                />
              </div>
              <div className="emailInput">
                <input 
                  className="emailName" 
                  placeholder="E-mail*"
                  value={inputEmailName}
                  autoComplete="nope"
                  onChange={(e) => handleInputChange(e, setInputEmailName)}     
                />
              </div>
              </div>
              <p>lussopack is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you:</p>
              <p>
                <input 
                  type="checkbox" 
                  className="checkbox"
                  checked={inputAccept1}
                  onChange={(e) => handleInputChange(e, setInputAccept1)}  
                />
                 I agree to receive other communications from lussopack.
              </p>
              <p>In order to provide you the content requested, we need to store and process your personal data. If you consent to us storing your personal data for this purpose, please tick the checkbox below.</p>
              <p>
                <input 
                  type="checkbox" 
                  className="checkbox"
                  checked={inputAccept2}
                  onChange={(e) => handleInputChange(e, setInputAccept2)}  
                />
                I agree to allow lussopack to store and process my personal data.*
              </p>
              <p>You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy.</p>
              <div className="downLoadFile">
                <button onClick={downloadFile}>Download File</button>
              </div>
            </div>
            }
          </div>
        </Media>
      </MediaContextProvider>
    </>
  );
}

export default ModalDownload;
