import React from 'react';
// import { useTranslation } from "react-i18next";
import './reviews.scss';
// import Image from "../../components/image";
import { asset } from "../../helpers/dom.helper";
import Image from "../../components/image";
import { Media, MediaContextProvider } from '../../media';

const Reviews = React.memo(() => {
  // const { t } = useTranslation(['translation', 'customer'])

  return (
    <MediaContextProvider>
      <Media greaterThanOrEqual="md">
        <div id="reviews">      
            <h2 className="text-header">Reviews</h2>
            <div className='reviewsContainer'>
              <div className='reviews'>
                <img
                  alt='reviews1'
                  src={asset('/images/home/reviews/desktop/reviews1.webp')}  
                  width="100%"
                  height="auto"
                />

                <div className='reviewsText'>
                  <img
                    className='qtop'
                    alt='qtop'
                    src={asset('/images/home/reviews/qTop.svg')}  
                    width="16"
                    height="14"
                  />
                    <p>I love the tray! It's customizable and the color fits my drawersvery well. Lussopack's teamalso offer great service.</p>            
                  <img
                    className='qbot'
                    alt='qbot'
                    src={asset('/images/home/reviews/qBot.svg')}  
                    width="16"
                    height="14"
                  />
                </div>
              </div>

              <div className='reviews'>
                <img
                  alt='reviews2'
                  src={asset('/images/home/reviews/desktop/reviews2.webp')} 
                  width="100%"
                  height="auto" 
                />
                <div className='reviewsText'>
                <img
                    className='qtop'
                    alt='qtop'
                    src={asset('/images/home/reviews/qTop.svg')}  
                    width="16"
                    height="14"
                  />
                <p>I finally found a one-stop service for luxury packaging. The brand is professional in producing large quantities of high quality packaging.</p>
                <img
                    className='qbot'
                    alt='qbot'
                    src={asset('/images/home/reviews/qBot.svg')}  
                    width="16"
                    height="14"
                  />
                </div>
              </div>

              <div className='reviews'>
                <img
                  alt='reviews3'
                  src={asset('/images/home/reviews/desktop/reviews3.webp')}  
                  width="100%"
                  height="auto"
                />
                <div className='reviewsText'>
                <img
                    className='qtop'
                    alt='qtop'
                    src={asset('/images/home/reviews/qTop.svg')}  
                    width="16"
                    height="14"
                  />
                  <p>The jewelry box looks very elegant and can also be customized. Lussopack provides plenty of options and materials as well.</p>
                  <img
                    className='qbot'
                    alt='qbot'
                    src={asset('/images/home/reviews/qBot.svg')}  
                    width="16"
                    height="14"
                  />
                </div>
              </div>

            </div>
            <div>
              <h2 className="text-header">Our Reference</h2>
              <div className='referenceLogo'>
                <div className='imgContainer'>
                  <a href='https://www.bulgari.com/' target="_blank" rel="noopener noreferrer">
                    <Image
                      src={asset('/images/home/reviews/bulgarilogo.webp')}
                      alt="bulgarilogo"
                      className="img-fluid"
                      width="100%"
                      height="auto"
                    />
                  </a>
                </div>
                <div className='imgContainer'>
                  <a href='https://www.swatch.com/' target="_blank" rel="noopener noreferrer">
                    <Image
                      src={asset('/images/home/reviews/swatchlogo.webp')}
                      alt="swatchlogo"
                      className="img-fluid"
                      width="100%"
                      height="auto"
                    />
                  </a>
                </div>
                <div className='imgContainer'>
                  <a href='https://www.viviennewestwood.com ' target="_blank" rel="noopener noreferrer">
                    <Image
                      src={asset('/images/home/reviews/vivien.webp')}
                      alt="vivien"
                      className="img-fluid"
                      width="100%"
                      height="auto"
                    />
                  </a>
                </div>
                <div className='imgContainer'>
                  <a href='https://www.porsche.com/ ' target="_blank" rel="noopener noreferrer">
                    <Image
                      src={asset('/images/home/reviews/porsche.webp')}
                      alt="porsche"
                      className="img-fluid"
                      width="100%"
                      height="auto"
                    />
                  </a>
                </div>
                <div className='imgContainer'>
                  <a href='https://www.fourseasons.com ' target="_blank" rel="noopener noreferrer">
                  <Image
                      src={asset('/images/home/reviews/fourSeasons.webp')}
                      alt="fourSeasons"
                      className="img-fluid"
                      width="100%"
                      height="auto"
                    />
                  </a>
                </div>
              </div>
            </div>

        </div>
      </Media>

      <Media lessThan="md">
      <div id="reviewsMobile">      
            <h2 className="text-header">Reviews</h2>
            <div className='reviewsContainer'>
              <div className='reviews'>
                <img
                  alt='reviews1'
                  src={asset('/images/home/reviews/mobile/reviews1.webp')}  
                  width="325"
                  height="345"
                />

                <div className='reviewsText'>
                  <img
                    className='qtop'
                    alt='qtop'
                    src={asset('/images/home/reviews/qTop.svg')}  
                    width="16"
                    height="14"
                  />
                    <p>I love the tray! It's customizable and the color fits my drawers very well. Lussopack's team also offer great service.</p>            
                  <img
                    className='qbot'
                    alt='qbot'
                    src={asset('/images/home/reviews/qBot.svg')}  
                    width="16"
                    height="14"
                  />
                </div>
              </div>

              <div className='reviews'>
                <img
                  alt='reviews2'
                  src={asset('/images/home/reviews/mobile/reviews2.webp')}  
                  width="325"
                  height="345"
                />
                <div className='reviewsText'>
                <img
                    className='qtop'
                    alt='qtop'
                    src={asset('/images/home/reviews/qTop.svg')}  
                    width="16"
                    height="14"
                  />
                <p>I finally found a one-stop service for luxury packaging. The brand is professional in producing large quantities of high quality packaging.</p>
                <img
                    className='qbot'
                    alt='qbot'
                    src={asset('/images/home/reviews/qBot.svg')}  
                    width="16"
                    height="14"
                  />
                </div>
              </div>

              <div className='reviews'>
                <img
                  alt='reviews3'
                  src={asset('/images/home/reviews/mobile/reviews3.webp')}  
                  width="325"
                  height="345"
                />
                <div className='reviewsText'>
                <img
                    className='qtop'
                    alt='qtop'
                    src={asset('/images/home/reviews/qTop.svg')}  
                    width="16"
                    height="14"
                  />
                  <p>The jewelry box looks very elegant and can also be customized. Lussopack provides plenty of options and materials as well.</p>
                  <img
                    className='qbot'
                    alt='qbot'
                    src={asset('/images/home/reviews/qBot.svg')}  
                    width="16"
                    height="14"
                  />
                </div>
              </div>

            </div>

            <div>
              <h2 className="text-header">Our Reference</h2>
              <div className='referenceLogo'>
              <a className='mt-2' href='https://www.bulgari.com/' target="_blank" rel="noopener noreferrer">
                <img
                  src={asset('/images/home/reviews/mobile/bulgarilogo.webp')}
                  alt="bulgarilogo"                
                  width="50%"
                  height="50%"
                />
              </a>
                
              <a href='https://www.swatch.com/' target="_blank" rel="noopener noreferrer">
                <img
                  src={asset('/images/home/reviews/mobile/swatchlogo.webp')}
                  alt="swatchlogo"                
                  width="50%"
                  height="50%"
                />
              </a>

              <a href='https://www.porsche.com/' target="_blank" rel="noopener noreferrer">
                <img
                  src={asset('/images/home/reviews/mobile/porsche.webp')}
                  alt="porsche"                
                  width="50%"
                  height="50%"
                />
              </a>
              <a href='https://www.viviennewestwood.com ' target="_blank" rel="noopener noreferrer">
                <img
                  src={asset('/images/home/reviews/mobile/vivien.webp')}
                  alt="vivien"                
                  width="50%"
                  height="50%"
                />
              </a>


              <a href='https://www.fourseasons.com ' target="_blank" rel="noopener noreferrer">
                <img
                  src={asset('/images/home/reviews/mobile/fourSeasons.webp')}
                  alt="fourSeasons"                
                  width="50%"
                  height="50%"
                />
              </a>

              </div>
            </div>
        </div>
      </Media>
    </MediaContextProvider>
  )
})

export default Reviews