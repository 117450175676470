import React from "react";
import Slider from "react-slick";
import { asset } from "../../helpers/dom.helper";
import { Media, MediaContextProvider } from '../../media';
import './homeSilde.scss';

 const HeaderHomeSilde = React.memo(() => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay:true,
        autoplaySpeed:5000,
        pauseOnHover:false
    };
    
    return (
      <div id="header-home-silde">
        <MediaContextProvider>
        <Slider {...settings}>
          
          <div>
            <Media greaterThanOrEqual="md">
                <div className="text-center pt-5 pb-5 align-items-center justify-content-center" 
                     style={{background: `url(${asset('/images/homeSilde/bangkokGemsDesktop01.webp')}) no-repeat center top`,
                     backgroundSize:'cover',
                     height:'100vh',
                     width:'100%',                                    
                     }}>                
                </div>
            </Media>
            <Media lessThan="md">
            <div className="paddingTopp" style={{background: `url(${asset('/images/homeSilde/bangkokGemsPhone01.webp')}) no-repeat center top`,backgroundSize:'cover',height:'650px' }}>            
                    </div>
            </Media>
          </div>

          <div >
            <Media greaterThanOrEqual="md">
                <div className="text-center pt-5 pb-5 align-items-center justify-content-center" 
                     style={{background: `url(${asset('/images/homeSilde/bangkokGemsDesktop02.webp')}) no-repeat center top`,
                     backgroundSize:'cover',                     
                     height:'100vh',
                     width:'100%',                     
                     }}>
                </div>
            </Media>
            <Media lessThan="md">
            <div className="paddingTopp" 
             style={{background: `url(${asset('/images/homeSilde/bangkokGemsPhone02.webp')}) no-repeat center top`,backgroundSize:'cover',height:'650px' }}>            
                    </div>
            </Media>
          </div> 

          <div>
            <Media greaterThanOrEqual="md">
                <div className="text-center pt-5 pb-5 align-items-center justify-content-center" 
                     style={{background: `url(${asset('/images/homeSilde/bangkokGemsDesktop03.webp')}) no-repeat center top`,
                     backgroundSize:'cover',                     
                     height:'100vh',
                     width:'100%',                     
                     }}>                
                </div>
            </Media>
            <Media lessThan="md">
                    <div className="paddingTopp" style={{background: `url(${asset('/images/homeSilde/bangkokGemsPhone03.webp')}) no-repeat center top`,backgroundSize:'cover',height:'650px' }}>            
                    </div>
            </Media>
          </div>
          
        </Slider>
        
        </MediaContextProvider>
      </div>
     
    );
})
    
export default HeaderHomeSilde