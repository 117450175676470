import React from 'react';
import { Helmet } from "react-helmet";
// import { useTranslation } from 'react-i18next';
import LazyLoad from "react-lazyload";
import styles from './home.module.scss';
import HeaderHomeSilde from "./headerHomeSilde"
import WhyLussopackHome from "./whyLussoPackHome";
import PackagingAndDisplay from "./packagingAndDisplay";
import Reviews from "./reviews";
import ReadOurBlog from './readOurBlogs';


export default function Home() {

  return (
    <>
      <Helmet>
        <title>Lussopack: Premier Luxury Packaging Company & Manufacturer</title>
        <meta name="description" content="Choose Lussopack for exquisite luxury packaging. Stand out with bespoke designs. Contact us to start your project!" />
        <meta name="keywords" content="luxury packaging manufacturer" />
        <link rel="canonical" href="https://lussopack.com/" />  
      </Helmet>

      <div className={styles.home}>
        <LazyLoad once>
          <HeaderHomeSilde />
        </LazyLoad>
        
        <LazyLoad once>
          <WhyLussopackHome />
        </LazyLoad>

        <LazyLoad once>
          <PackagingAndDisplay />
        </LazyLoad>
        
       <LazyLoad once>
          <Reviews />
        </LazyLoad>
 
        <LazyLoad once>
          <ReadOurBlog />
        </LazyLoad>        
      </div>
    </>
  )

}
